import "./styles.css";

import PartySocket from "partysocket";

declare const PARTYKIT_HOST: string;

// Let's append all the messages we get into this DOM element
const output = document.querySelector("body .events .inner") as HTMLDivElement;
const status = document.querySelector("body .status") as HTMLDivElement;

const maps: { [key: string]: string } = {
  'user.authorization.grant': '{event.user_name | event.user_login} Signed in!',
  'channel.subscribe': 'User {event.user_name | event.user_login} subscribed at T{event.tier}',
  'channel.subscription.message': 'User {event.user_name | event.user_login} subscribed at T{event.tier} for {event.cumulative_months} months',
  'channel.subscription.gift': 'User {event.user_name | event.user_login} gifted x{event.total} subscription(s)',
  'channel.subscription.end': 'Subscription ended for user {event.user_name | event.user_login}',
}

const find = (obj: any, path: string) => {
  const parts = path.split('.');
  let current = obj;
  for (let i = 0; i < parts.length; i++) {
    if (current[parts[i]]) {
      current = current[parts[i]];
    } else {
      return '';
    }
  }

  return current;
}

const replacers = (parsed: any, map: string) => {
  return map.replace(
    /{([^}]+)}/g,
    (match, ...parts) => {
      const path = parts[0].split(' | ');
      let value = '';
      for (let i = 0; i < path.length; i++) {
        value = find(parsed, path[i]);
        if (value) {
          break;
        }
      }

      return value;
    }
  );
}

function setStatus(active: boolean) {
  status.classList.toggle("active", active);
}

function replay(event: any) {
  conn.send(JSON.stringify(event));
}

// Helper function to add a new line to the DOM
function add(text: string) {
  let parsed: any = null;

  try {
    parsed = JSON.parse(text);
    text = JSON.stringify(parsed, null, 2);

    if(maps[parsed.subscription.type]) {
      text = replacers(parsed, maps[parsed.subscription.type]);
    }
  } catch (e) {
    // ignore
  }

  const textNode = document.createTextNode(text);
  const pre = document.createElement('pre');
        pre.appendChild(textNode);

  const replayButton = document.createElement('button');
        replayButton.innerText = 'Replay';
        replayButton.onclick = () => {
          const event = JSON.parse(JSON.stringify(parsed));
          replay(event);
        };

  const timestamp = document.createElement('legend');
        timestamp.classList.add('timestamp');
        timestamp.innerText = new Date().toUTCString();

  timestamp.appendChild(replayButton);

  const inner = document.createElement('fieldset');
        inner.classList.add('block');
        inner.appendChild(timestamp);
        inner.appendChild(pre);

  output.appendChild(inner);
  output.dataset.event = JSON.stringify(parsed);
}

status.onclick = () => {
  add('ping');
}

// A PartySocket is like a WebSocket, except it's a bit more magical.
// It handles reconnection logic, buffering messages while it's offline, and more.
const conn = new PartySocket({
  host: PARTYKIT_HOST,
  party: 'twitch_events',
  room: "call",
});

// You can even start sending messages before the connection is open!
conn.addEventListener("message", (event) => {
  if(event.data === 'pong') {
    setStatus(true);
    return;
  }

  add(event.data);
});

// Let's listen for when the connection opens
// And send a ping every 2 seconds right after
conn.addEventListener("open", () => {

});

// Let's listen for when the connection closes
conn.addEventListener("close", () => {
  setStatus(false);
});